<template>
  <div class="main">
    <img src="../images/logoNew.png" alt="" class="logo">
    <img src="../images/loginImg.png" alt="" class="loginImg">
    <div class="loginWrap">
      <div class="menuList">
        <div :class="type == 1 ? 'selectMenu' : ''" @click="type = 1" class="cursor">
          <div>手机号登录</div>
          <div v-if="type == 1" class="line"></div>
        </div>
        <div :class="type == 2 ? 'selectMenu' : ''" @click="type = 2" class="cursor">
          <div>账号登录</div>
          <div v-if="type == 2" class="line"></div>
        </div>
      </div>

      <div class="inputWrapPassWord" v-if="type == 1">
        <el-input placeholder="手机号" v-model="phone" class="mt30" @keyup.enter.native='throttlePhoneLogin'
          maxlength="11" />
        <div>
          <el-input placeholder="短信验证码" v-model="code" class="mt30" @keyup.enter.native='throttlePhoneLogin'
            maxlength="6">
            <el-button slot="append" @click="throttleSendCode" v-if="!isCountDown"
            :style="[{width:'114px'},{color:isBold?'#333':''}]">发送验证码</el-button>
            <el-button slot="append" v-else style="width: 114px;">{{ countDownNum }}</el-button>
          </el-input>
        </div>
        <el-button class="loginBtn mt30" @click="throttlePhoneLogin">登录</el-button>
      </div>

      <div class="inputWrapPassWord" v-if="type == 2">
        <el-input placeholder="用户名" v-model="name" class="mt30" @keyup.enter.native='throttleAccountLogin' />
        <el-input placeholder="密码" v-model="password" type="password" show-password class="mt30"
          @keyup.enter.native='throttleAccountLogin' />
        <el-button class="loginBtn mt30" @click="throttleAccountLogin">登录</el-button>
        <div class="forgetPw mt15 cursor" @click="isForgotPassword = true">忘记密码?</div>
      </div>
    </div>
    <!-- 账号绑定 -->
    <div class="loginWrap" v-if="!isHaveAccount">
      <div class="title">账号绑定</div>
      <div class="tips mt15">未查询到手机号相关账号,请绑定账号</div>
      <el-input placeholder="用户名" v-model="name" class="mt15" />
      <el-input placeholder="密码" v-model="password" type="password" show-password class="mt30" />
      <div class="warn mt15">{{ isHaveUser ? '' : '*未查询到用户名相关账号,请联系管理员或重新尝试' }}</div>
      <div class="btns">
        <el-button class="goBack mt15 w50" @click="goLogin">返回</el-button>
        <el-button class="loginBtn mt15 w50" @click="bindUser">绑定并登录</el-button>
      </div>
    </div>

    <!-- 忘记密码 -->
    <div class="loginWrap" v-if="isForgotPassword">
      <div class="title">忘记密码</div>
      <el-input placeholder="手机号" v-model="phone" class="mt30" @keyup.enter.native='throttlePhoneLogin'
        maxlength="11" />
      <div>
        <el-input placeholder="短信验证码" v-model="code" class="mt30" @keyup.enter.native='throttlePhoneLogin'
          maxlength="6">
          <el-button slot="append" @click="throttleSendCode" v-if="!isCountDown" :style="[{width:'114px'},{color:isBold?'#333':''}]">发送验证码</el-button>
          <el-button slot="append" v-else style="width: 114px;">{{ countDownNum }}</el-button>
        </el-input>
      </div>
      <el-input placeholder="新密码" v-model="password" type="password" maxlength="6" show-password class="mt15" />
      <div class="btns">
        <el-button class="goBack mt15 w50" @click="isForgotPassword = false">返回</el-button>
        <el-button class="loginBtn mt15 w50" @click="updatePasswer">修改并登录</el-button>
      </div>
    </div>

    <div class="Fortherecord">
      <a target="_blank" href=" https://beian.miit.gov.cn/"><img src="../images/record.png" />
        <p>浙公网安备 33010202002041号 浙ICP备2021037496号-1</p>
      </a>
    </div>
  </div>
</template>

<script>
import { loginNew, sendCheckCode, verifyCheckCode, phoneCodeLogin, agentUpdate, updatepw } from '../apis/index'
import { throttle, isValidPhoneNumber } from '../utils/utils'
export default {
  data() {
    return {
      name: '',
      password: '',
      phone: '',
      code: '',
      type: 2,//1手机号登录，2帐号登录，
      isCountDown: false,//是否验证码倒计时
      countDownNum: 119,//开始倒计时
      isHaveAccount: true,//通过手机号登录是否有帐号
      isHaveUser: true,//是否有用户
      isForgotPassword: false,//是否忘记密码
      isBold:false,
    }
  },
  watch:{
    phone(newVal,oldVla){//手机号输入满足条件发送验证码问题加粗变黑
      if(isValidPhoneNumber(this.phone)){
        this.isBold=true
      }else{
        this.isBold=false
      }
    },
  },
  computed: {

  },
  created() {

  },
  methods: {
    throttleAccountLogin: throttle(function () {//账号密码节流
      this.accountLogin()
    }, 1000),

    throttlePhoneLogin: throttle(function () {//手机号登录节流
      if (!this.checkPhone()) return
      if (!this.code) {
        this.$message.error('验证码不能为空')
        return
      }
      this.phoneLogin()
    }, 1000),
    throttleSendCode: throttle(function () {//发送验证码节流
      if (!this.checkPhone()) return
      this.sendCode()
    }, 1000),
    goLogin() {//账号绑定返回
      this.isHaveAccount = true
    },
    accountLogin() {//账号密码登录
      const params = {
        account: this.name,
        pwd: this.password
      }
      loginNew(params).then(res => {
        if (res.data.code == 200) {
          this.$message({
            showClose: true,
            message: '登录成功',
            type: 'success'
          });
          debugger
          this.$store.commit('setToken', res.data.data.token)
          this.$store.commit('setBrandId', res.data.data.brandId)
          this.$store.commit('setUserInfo', res.data.data.name)
          this.$store.commit('setPermissionsList', res.data.data.permissionRoleVo.children)
          if (res.data.data.userId) {
            this.$store.commit('setUserId', res.data.data.userId)
          }
          if (res.data.data.roleId) {
            sessionStorage.setItem('permissionRoleVoRoleId', res.data.data.roleId)
            this.$store.commit('setRoleId', res.data.data.roleId)
          } else {
            this.$store.commit('setRoleId', '')
          }
          setTimeout(() => {
            if (res.data.data.permissionRoleVo.children[0].uri) {
              this.$router.push({ path: res.data.data.permissionRoleVo.children[0].uri })
            } else {
              this.$router.push({ path: res.data.data.permissionRoleVo.children[0].children[0].uri })
            }
          }, 500)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    phoneLogin() {//手机号登录按钮
      if (!this.isHaveAccount) return
      this.verifyCode()
    },
    async sendCode() {//发送验证码
      console.log('发送验证码')
      const { data } = await sendCheckCode({ phoneNumber: this.phone })
      if (data.code == 200) {
        this.$message.success('已发送,注意查收')
        // this.code = data.msg
        this.isCountDown = true
        let interval = setInterval(() => {
          this.countDownNum = this.countDownNum - 1
          if (this.countDownNum <= 0) {
            this.isCountDown = false
            this.countDownNum = 119
            clearInterval(interval)
            return
          }
        }, 1000);
      } else {
        this.$message.error(data.msg)
      }

    },
    async verifyCode() {//校验验证码
      const { data } = await verifyCheckCode({ phoneNumber: this.phone, checkCode: this.code })
      console.log(data)
      if (data.code == 200) {
        //成功调用手机号登录
        this.loginPhoneCode()
      } else {
        this.$message.error(data.msg)
      }
    },
    async loginPhoneCode() {//手机号登录方法
      const { data } = await phoneCodeLogin({ checkCode: this.code, phone: this.phone })
      if (data.code == 200) {
        this.$message({
          showClose: true,
          message: '登录成功',
          type: 'success'
        });
        this.$store.commit('setToken', data.data.token)
        this.$store.commit('setBrandId', data.data.brandId)
        this.$store.commit('setUserInfo', data.data.name)
        this.$store.commit('setPermissionsList', data.data.permissionRoleVo.children)
        if (data.data.userId) {
          this.$store.commit('setUserId', data.data.userId)
        }
        if (data.data.roleId) {
          this.$store.commit('setRoleId', data.data.roleId)
        } else {
          this.$store.commit('setRoleId', '')
        }
        setTimeout(() => {
          if (data.data.permissionRoleVo.children[0].uri) {
            this.$router.push({ path: data.data.permissionRoleVo.children[0].uri })
          } else {
            this.$router.push({ path: data.data.permissionRoleVo.children[0].children[0].uri })
          }
        }, 500)
      } else {
        this.isHaveAccount = false
        this.$message.error(data.msg)
      }
    },
    checkPhone() {//校验手机号
      let phoneIsAllow = isValidPhoneNumber(this.phone)
      if (!phoneIsAllow) {
        this.$message.error('手机号不满足')
        return false
      } else {
        return true
      }
    },
    bindUser() {//绑定用户名登录
      // this.isHaveUser=false
      const params = {
        account: this.name,
        pwd: this.password
      }
      loginNew(params).then(res => {
        if (res.data.code == 200) {
          this.$message({
            showClose: true,
            message: '登录成功',
            type: 'success'
          });
          this.updateBindPhone(res.data.data)//绑定手机号
          this.$store.commit('setToken', res.data.data.token)
          this.$store.commit('setBrandId', res.data.data.brandId)
          this.$store.commit('setUserInfo', res.data.data.name)
          this.$store.commit('setPermissionsList', res.data.data.permissionRoleVo.children)
          if (res.data.data.userId) {
            this.$store.commit('setUserId', res.data.data.userId)
          }
          if (res.data.data.roleId) {
            this.$store.commit('setRoleId', res.data.data.roleId)
          } else {
            this.$store.commit('setRoleId', '')
          }
          setTimeout(() => {
            if (res.data.data.permissionRoleVo.children[0].uri) {
              this.$router.push({ path: res.data.data.permissionRoleVo.children[0].uri })
            } else {
              this.$router.push({ path: res.data.data.permissionRoleVo.children[0].children[0].uri })
            }
          }, 500)
        } else {
          this.$message.error(res.data.msg)
        }
      })
    },
    async updateBindPhone(res) {//绑定手机号
      console.log(res)
      const params = {
        phone: this.phone,
        brandId: res.brandId,
        userId: res.userId,
        // agentId: this.userId,
      }
      const { data } = await agentUpdate(params)
      if (data.code == 200) {
        this.$message({
          type: 'success',
          message: '更新成功!'
        });
        this.dialogVisible3 = false
        // this.list3()
      } else {
        this.$message({
          type: 'error',
          message: data.msg
        })
      }
    },
  isSixDigitNumber(input) {
    return /^\d{6}$/.test(input);
},
    updatePasswer() {//修改并登录帐号
      if (!this.checkPhone()) return
      if (!this.code) {
        this.$message.error('验证码不能为空')
        return
      }
      if (!this.isSixDigitNumber(this.password)) {
        this.$message.error('密码不能为空,不能包含符号且只能为六位数')
        return
      }
      //手机号验证
      verifyCheckCode({ phoneNumber: this.phone, checkCode: this.code }).then(res => {
        if (res.data.code == 200) {
          //调用手机号登录
          phoneCodeLogin({ checkCode: this.code, phone: this.phone }).then(res => {
            if (res.data.code == 200) {//登录成功
              let loginObj = res.data
              this.$store.commit('setToken', loginObj.data.token)
              const params = {
                phone:this.phone,
                checkCode:this.code,
                pwdSix: this.password,
              }
              updatepw(params).then(res => {//修改密码
                if (res.data.code == 200) {//修改成功跳转登录页面
                    this.$message({
                      showClose: true,
                      message: '修改成功',
                      type: 'success'
                    });
                    // this.updateBindPhone(loginObj.data)//绑定手机号

                    this.$store.commit('setBrandId', loginObj.data.brandId)
                    this.$store.commit('setUserInfo', loginObj.data.name)
                    this.$store.commit('setPermissionsList', loginObj.data.permissionRoleVo.children)
                    if (loginObj.data.userId) {
                      this.$store.commit('setUserId', loginObj.data.userId)
                    }
                    if (loginObj.data.roleId) {
                      this.$store.commit('setRoleId', loginObj.data.roleId)
                    } else {
                      this.$store.commit('setRoleId', '')
                    }
                    setTimeout(() => {
                      if (loginObj.data.permissionRoleVo.children[0].uri) {
                        this.$router.push({ path: loginObj.data.permissionRoleVo.children[0].uri })
                      } else {
                        this.$router.push({ path: loginObj.data.permissionRoleVo.children[0].children[0].uri })
                      }
                    }, 500)

                } else {
                  this.$message.error(res.data.msg)
                }
              })
            } else {
              this.$message.error(res.data.msg)
            }
          })
        } else {
          this.$message.error(res.data.msg)
        }
      })

    },
  },
}
</script>

<style lang="less" scoped>
/deep/.el-input-group__append {
  background: transparent
}

.main {
  width: 100%;
  height: 100%;
  background-image: url('../images/bgNew.png');
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
}

.logo {
  position: absolute;
  top: 5%;
  left: 5%;
}

.loginImg {
  position: absolute;
  width: 33vw;
  height: auto;
  top: 50%;
  left: 20%;
  transform: translate(-20%, -50%);
}

.loginWrap {
  position: absolute;
  // width: 24vw;
  // height: 25vw;
  width: calc(465px * 0.8);
  height: calc(472px * 0.8);
  background: #FFFFFF;
  top: 50%;
  left: 80%;
  transform: translate(-80%, -50%);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 45px 39px;
  box-sizing: border-box;
}

.menuList {
  display: flex;
  font-size: 15px;
  color: #3D3D3D;
}

.menuList>div {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.menuList>div:last-child {
  margin-left: 50px;
}

.selectMenu {
  font-weight: bold;
}

.line {
  width: 100%;
  height: 4px;
  background: #37CEAC;
  margin-top: 5px;
}

.inputWrapPassWord {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.loginBtn {
  background: #18CFA7;
  color: #FFFFFF;
  font-size: 15px;
}

.forgetPw {
  color: #1890FF;
}

.mt30 {
  margin-top: 28px;
}

.mt15 {
  margin-top: 15px;
}

.cursor:hover {
  cursor: pointer;
}

.title {
  font-weight: bold;
}

.tips {
  font-size: 12px;
  color: #8D9699;
}

.warn {
  font-size: 12px;
  color: #FF4D4F;
}

/deep/.el-input__inner {
  height: calc(62px * 0.7);
  // border-radius: 10px;
}

/deep/.el-button {
  height: calc(62px * 0.7);
  // border-radius: 10px;
}

.btns {
  display: flex;
  justify-content: space-between;
}

.w50 {
  width: 50%;
}

.Fortherecord {
  width: 300px;
  margin: 0 auto;
  padding: 20px 0;
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  bottom: 5%;
}

.Fortherecord a {
  display: flex;
  text-decoration: none;
  height: 20px;
  line-height: 20px;
  float: left;
}

.Fortherecord p {
  float: left;
  height: 20px;
  line-height: 20px;
  margin: 0px 0px 0px 5px;
  color: #fff;
  text-align: center;
  color: #CECECE
}
</style>
